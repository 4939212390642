// Webpack Imports
import * as bootstrap from 'bootstrap';


(function() {
    'use strict';
    console.log('opa');

    // Focus input if Searchform is empty
    [].forEach.call(document.querySelectorAll('.search-form'), (el) => {
        el.addEventListener('submit', function(e) {
            var search = el.querySelector('input');
            if (search.value.length < 1) {
                e.preventDefault();
                search.focus();
            }
        });
    });

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'focus',
        });
    });
})();


jQuery(document).ready(function() {



jQuery('#menu-menu .menu-item-has-children').on('mouseover', function(){
    var getId = jQuery(this).attr('id');
    console.log(getId);
      jQuery('.menu-submenu .submenus > li > ul').hide();
      jQuery('.menu-submenu .submenus > li.'+getId+' > ul').show();
  })
  
  
  



    jQuery('#menu-menu > .menu-item-has-children > a').on('click', function(e){
        e.preventDefault();
        jQuery(this).parent().toggleClass('active');
    });

    jQuery(document).bind('gform_page_loaded', function(event, form_id, current_page){
        
        if(form_id == 5) {
            jQuery(document).scrollTop(0);
        }
    })




    jQuery('#menu-menu .menu-item-has-children > a').on('mouseenter', function() {
        jQuery(this).parent().addClass('active');
        let item_id = jQuery(this).attr('id');
        
        // Slide down the submenu
        jQuery('.menu-submenu').slideDown();
        jQuery('.navigation .nav-row').addClass('heightMinus');
    
        // Highlight the matching submenu item
        jQuery('#menu-menu-1 li').removeClass('active');
        jQuery('#menu-menu-1 li.' + item_id).addClass('active');
    });

    jQuery('.navigation').on('mouseleave', function() {
        jQuery('.menu-item-has-children').removeClass('active');
        jQuery('.menu-submenu').slideUp();
        jQuery('.navigation .nav-row').removeClass('heightMinus');
        jQuery('.menu-item-has-children').removeClass('active');
    });

    //hamburger
    jQuery('#nav-icon3').click(function(){
		jQuery(this).toggleClass('open');
        jQuery('body').toggleClass('noScroll');
        jQuery('.navigation .nav-row .col-menu').toggleClass('show');
        jQuery('.navigation .nav-row .col-logo').toggleClass('show');
	});

    // Hero 

    jQuery('a').on('click', function(e){
        if(this.hash == '#popup-content') {
            e.preventDefault();
            jQuery('.popupForm').addClass('show');
        }
    })

    jQuery('.open-form').on('click', function(e){
        e.preventDefault();
        jQuery('.popupForm').addClass('show');
    })


    jQuery('a[href="#open-form"]').on('click', function(e){
        e.preventDefault();
        jQuery('.popupForm').addClass('show');
    })
    jQuery('.popupForm .popupForm__content .text .back, .popupForm .close-icon img').on('click', function(e){
        jQuery('.popupForm').removeClass('show');
    })

    jQuery('.products .carouselProjects li .see-review').on('click', function(e){
        jQuery(this).parent().find('.client').toggleClass('show');
        var button = jQuery(this).find('span');
        jQuery(this).toggleClass('rotate');
        if (button.text() === "See Review") {
          button.text("Hide Review");
        } else {
          button.text("See Review");
        }
    });


    jQuery('.products-new .carouselProjects li .see-review').on('click', function(e){
        jQuery(this).parent().find('.client .desc').slideToggle();
        var button = jQuery(this).find('span');
        jQuery(this).toggleClass('rotate');
        if (button.text() === "See Review") {
          button.text("Hide Review");
        } else {
          button.text("See Review");
        }
    });

    function getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|jQuery)');
        return keyValue ? keyValue[2] : null;
    }  

    // if(getCookie('animacja') == 'set') {
    //     jQuery('body.home').removeClass('noScroll');
    // } else{
    //     jQuery('body.home').addClass('noScroll');

    // }

  
    


   
    jQuery(".hamburger").click(function() {
        jQuery(this).toggleClass("is-active");
        jQuery('.nav-side').toggleClass("show");
    });

    jQuery('.button-hero').on('click', function(){
        let pageHeight = window.innerHeight - 100
        window.scrollBy(0, pageHeight);
    })


    jQuery('section.problems .navTabs-single').on('click', function(){
        var getData = jQuery(this).attr('data-tab');
        console.log(getData);
        jQuery('section.problems .navTabs-single').removeClass('active');
        jQuery(this).addClass('active');

        jQuery('section.problems .tabsContent-single').hide();
        jQuery('section.problems .tabsContent-single.'+getData).fadeIn(700);

    })

    if(jQuery('.carouselBlog').length > 0){
        
        var splide = new Splide( '.carouselBlog', {
            perPage: 3,
            gap: 64,
            arrows: false,
            pagination: true,
            breakpoints: {
                1200: {
                    perPage: 2,
                    pagination: true,
                },
                640: {
                    perPage: 1,
                },
          }
        });
        splide.mount();
        jQuery('.rowProjects__filters__single').on('click', function() {
            // Get the slide number from the data-slide attribute
            var slideNum = jQuery(this).data('slide');
    
            // Go to the desired slide
            splide.go(slideNum);
    
            // Remove active class from all divs and add to the clicked one
            jQuery('.rowProjects__filters__single').removeClass('active');
            jQuery(this).addClass('active');
        });
    }



    if(jQuery('.splideLogotype').length > 0){
        
        var splide = new Splide( '.splideLogotype', {
            // perPage: 6,
            
            gap: 100,
            autoWidth: true,
            // type   : 'loop',
            autoplay: true,
            arrows: false,
            pagination: false,
            breakpoints: {
                1200: {
                    perPage: 2,
                },
                640: {
                    gap: 20,
                    // perPage: 2,
                    // autoWidth: false,
                },
          }
        });
        splide.mount();
        
    }

    if(jQuery('.splideExperience').length > 0){
        
        var splideExperience = new Splide( '.splideExperience', {
            // perPage: 6,
            
            gap: 30,
            // autoWidth: true,
            type   : 'fade',
            // autoplay: true,
            wheelSleep: 500,
            arrows: false,
            pagination: false,
            perPage: 1,
            releaseWheel: true,
       
        });
        splideExperience.mount();

        splideExperience.on('moved', (newIndex) => {
            var index = newIndex;
            jQuery('.experience__image--single').hide();  
            jQuery('.experience__image--single').eq(index).show();
            console.log(index);

            if(index == 0) {
                jQuery('.arrows .right').removeClass('hidden');
                jQuery('.arrows .left').addClass('hidden');
            }

            if(index == 1) {
                jQuery('.arrows .left').removeClass('hidden');
                jQuery('.arrows .right').removeClass('hidden');
            }

            if(index == 2) {
                jQuery('.arrows .left').removeClass('hidden');
                jQuery('.arrows .right').addClass('hidden');
            }

            
        });

        // splideExperience.on('move', function(){
        //     console.log('zmiana experience');
        // })

        const nextButton = document.querySelectorAll('.arrows .right');
        const prevButton = document.querySelectorAll('.arrows .left');

        jQuery(nextButton).on('click', function(){
            splideExperience.go('+1');
        })

        jQuery(prevButton).on('click', function(){
            splideExperience.go('-1');
        })
          
        
    }


    if(jQuery('.splideTeam').length > 0){
        
        var splide = new Splide( '.splideTeam', {
            autoWidth: true,
            gap: 32,
            drag   : 'free',
            arrows: false,
            pagination: false,
            breakpoints: {
       
                640: {
                    gap:16,
                },
          }
        });
        splide.mount();
        
    }


    if(jQuery('.carouselProjects').length > 0){
        
        var splide = new Splide( '.carouselProjects', {
            gap: 30,
            // autoWidth: true,
            type   : 'fade',
            // autoplay: true,
            // wheelSleep: 500,
            arrows: false,
            pagination: false,
            perPage: 1,
            // autoHeight: true,
        });
        function adjustHeight(splide) {
            var activeSlide = splide.Components.Elements.slides[splide.index];
            var slideHeight = activeSlide.offsetHeight;
            splide.root.style.height = slideHeight + 'px';
          }
          splide.on('mounted move', function () {
            adjustHeight(splide);
            console.log('zmiana slidera');
          })
      
        splide.mount();

        jQuery('.rowProjects__filters__single').on('click', function() {
            // Get the slide number from the data-slide attribute
            var slideNum = jQuery(this).data('slide');
    
            // Go to the desired slide
            splide.go(slideNum);
    
            // Remove active class from all divs and add to the clicked one
            jQuery('.rowProjects__filters__single').removeClass('active');
            jQuery(this).addClass('active');
        });
        
    }






window.addEventListener('load', function() {
    var containers = document.querySelectorAll('.colContent-right__single');
    var targetPosition = window.innerHeight * 0.4; // 50% wysokości viewportu
    var closestContainer = null;
    var closestDistance = Number.MAX_SAFE_INTEGER;
    var scrollTimeout;
  
    function calculateClosestContainer() {
      closestContainer = null;
      closestDistance = Number.MAX_SAFE_INTEGER;
  
      containers.forEach(function(container) {
        var containerRect = container.getBoundingClientRect();
        var containerCenter = containerRect.top + containerRect.height / 2;
        var containerCenterOffset = Math.abs(containerCenter - targetPosition);
  
        if (containerCenterOffset < closestDistance) {
          closestContainer = container;
          closestDistance = containerCenterOffset;
        }
      });
  
      // Usuń klasę .przyg-wlosow-active ze wszystkich kontenerów
      containers.forEach(function(container) {
        container.classList.remove('slide-step');
          
      
      });
  
      // Jeśli istnieje najbliższy kontener, dodaj klasę .przyg-wlosow-active
      if (closestContainer) {
        closestContainer.classList.add('slide-step');
        var step = closestContainer.getAttribute('data-active');
    //   console.log(step);
          jQuery('.leftElement').removeClass('active');
          jQuery('.leftElement-'+step).addClass('active');
      
      }
    }
  
    calculateClosestContainer(); // Wywołaj funkcję raz przy załadowaniu strony
  
    function onScroll() {
      clearTimeout(scrollTimeout);
  
      scrollTimeout = setTimeout(function() {
        calculateClosestContainer();
      }, 0); // Zmień wartość opóźnienia na odpowiednią dla Twoich potrzeb
    }
  
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', calculateClosestContainer);
  });
  
  

//   Ajax Posts 

let currentPage = 1;
jQuery('#load-more').on('click', function() {
  currentPage++; 

  jQuery.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    dataType: 'html',
    data: {
      action: 'weichie_load_more',
      paged: currentPage,
    },
    success: function (res) {
      jQuery('section.blog .row-blog .grid').append(res);
    }
  });
});


jQuery(window).scroll(function() {

    if(jQuery('body').hasClass('single')) {
        // calculate the percentage the user has scrolled down the page
        var scrollwin = jQuery(window).scrollTop();
        var articleheight = jQuery('section.single-post-content').outerHeight(true);
        var windowWidth = jQuery(window).width();
        if (scrollwin >= jQuery('section.single-post-content').offset().top) {
            if (scrollwin <= (jQuery('section.single-post-content').offset().top + articleheight)) {
                jQuery('.bar-long').css('width', ((scrollwin - jQuery('section.single-post-content').offset().top) / articleheight) * 100 + "%");
            } else {
                jQuery('.bar-long').css('width', "100%");
            }
        } else {
            jQuery('.bar-long').css('width', "0px");
        }


        $(window).on('scroll', function () {
            var scrollPosition = $(window).scrollTop();
            var closestSectionId = null;
    
            // Iterate through each header element
            $(':header').each(function () {
              var headerOffset = $(this).offset().top;
    
              // Check if the header is in the viewport
              if (headerOffset <= scrollPosition) {
                closestSectionId = $(this).attr('id');
              }
            });
    
            // Remove the 'active' class from all TOC items
            $('.table-of-contents a').removeClass('active');
    
            // Add the 'active' class to the corresponding TOC item
            if (closestSectionId !== null) {
              $('.table-of-contents a[href="#' + closestSectionId + '"]').addClass('active');
            }
          });

  
    }


});

if(jQuery('body').hasClass('single')) {
    jQuery(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
    
        jQuery('html, body').animate({
            scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - 140
        }, 500);
    });
    jQuery('.content-head h2, .content-head h3, .content-head h4, .content-head h5, .content-head h6').each(function(){
        var title = jQuery(this).text();
        var urlFriendlyTitle = title.replace(/\?/g, '').replace(/ /g, '-').toLowerCase();
        jQuery(this).append('<span id="'+urlFriendlyTitle+'"></span>');
    })

    $('[id^="h-"]').each(function () {
        // Get the current id
        var currentId = $(this).attr('id');

        // Remove the 'h-' prefix
        var newId = currentId.replace('h-', '');

        // Set the new id
        $(this).attr('id', newId);
      });
}


      

$('.tablePost.table-of-contents ul li a').on('click', function(){
    $('.tablePost.table-of-contents ul li a').removeClass('active');
    $(this).addClass('active');
})

jQuery('.faq .faq-content__single').on('click', function(){
    jQuery('.faq .faq-content__single .answer').hide();
    jQuery('.faq .faq-content__single .question').removeClass('rotate');
    jQuery(this).find('.answer').show();
    jQuery(this).find('.question').addClass('rotate');
})


jQuery('.gform_page:last-child()').addClass('last-step');

jQuery('.buttons-step').on('click', function(){
    jQuery('.row-forms').removeClass('hide-content');
    jQuery('.row-first').addClass('hide-content');
});
    


$('.results-forms .row-video .video .play img').on('click', function(){
    $('.results-forms .row-video .video .poster-img').addClass('hide');
    $(this).hide();
    var video = $('.poster iframe').attr('src');
    $('.poster iframe').attr('src', `${video}&autoplay=1`) 
})



setTimeout(function(){
    $('.svg-animate').addClass('show');
}, 200);

$('.accordion-team .person-accordion .single-person-accordion button').on('click', function(){
    $(this).parent().find('.desc-hide').toggle();
    $(this).find('span').toggleClass('flex');
})



function generateTableOfContents() {
    const contentHead = $('.contentHead');
    const headings = contentHead.find('h1, h2, h3, h4, h5, h6');

    const tocContainer = $('#tableOfContents');
    const tocList = $('<ul class="list-unstyled font-18"></ul>');

    headings.each(function (index) {
        const listItem = $('<li></li>');
        const link = $('<a></a>');
      
        // Pobierz tekst z nagłówka
        var headingText = $(this).text();
      
        // Usuń wszystkie cyfry z tekstu za pomocą wyrażenia regularnego
        var modifiedText = headingText.replace(/\d/g, "");
      
        // Ustaw zmodyfikowany tekst w linku
        link.text(modifiedText);
        link.attr('href', `#section-${index + 1}`);
        listItem.append(link);
        tocList.append(listItem);
      
        // Ustaw identyfikator nagłówka
        $(this).attr('id', `section-${index + 1}`);
      });

    tocContainer.append(tocList);

    $(window).scroll(function () {
      const scrollPosition = $(window).scrollTop();
      const windowHeight = $(window).height();
      let closestHeading = null;
      let closestDistance = Number.MAX_SAFE_INTEGER;

      headings.each(function () {
        const sectionId = $(this).attr('id');
        const section = $(`#${sectionId}`);
        const offsetTop = section.offset().top;

        if (offsetTop <= scrollPosition + windowHeight && offsetTop + section.outerHeight() > scrollPosition) {
          const distance = Math.abs(offsetTop - scrollPosition);
          if (distance < closestDistance) {
            closestDistance = distance;
            closestHeading = sectionId;
          }
        }
      });

      // Dodaj klasę 'active' tylko dla najbliższego nagłówka
      tocList.find('a').removeClass('active');
      if (closestHeading) {
        tocList.find(`[href="#${closestHeading}"]`).addClass('active');
      }
    });
  }

  generateTableOfContents();

  if(jQuery('.form-estimate').length > 0) {

  var iloscPytan = jQuery('.form-estimate .gform_wrapper.gravity-theme legend').length;
  var numerPytania = 1;
    jQuery('.form-estimate .gform_wrapper.gravity-theme legend').each(function(){
        $(this).append('<div class="questionNumber">' + numerPytania + ' / ' + iloscPytan + '</div>');
        numerPytania++;
    })

    setInterval(function(){
        var numerPytania = 1;
        jQuery('.questionNumber').remove();
        jQuery('.form-estimate .gform_wrapper.gravity-theme legend').each(function(){
            $(this).append('<div class="questionNumber">' + numerPytania + ' / ' + iloscPytan + '</div>');
            numerPytania++;
        })
    }, 100);

}


  


});